<template>
    <div class="formcontainer">
        <span class="text-intro" v-html="$t('configurationText')">
        </span>
        <span class="errorelogin">{{$store.getters.authStatus}}</span>
        <div>
            <form class="login" @submit.prevent="login">
                <div>
                    <label>Username</label>
                    <input required v-model="username" type="text" placeholder="" />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        required
                        v-model="password"
                        type="password"
                        placeholder=""
                    />
                </div>
                <div>
                    <label for="nome">{{$t('configurationDeviceName')}}</label>
                    <input required v-model="nome" type="text" placeholder="" />
                </div>
                <div class="button-start-container">
                    <button type="submit" class="button-blue" >
                        <span class="button-label">
                            {{$t("configurationButton")}}
                        </span>
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      nome: ""
    };
  },
  methods: {
    login: function() {
      const { username, password,nome } = this;
      this.$store.dispatch(AUTH_REQUEST, { username, password, nome }).then(() => {
        this.$router.go(0);
      });
    }
  }
};
</script>
