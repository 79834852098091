<template>
    <div>
        <span class="text-avviso">{{ $t('textInizia') }}</span>
        <div class="formcontainer">
            <dynamic-form :id="userForm.id" :fields="userForm.fields" @submit="formSubmitted"/>
            <div class="button-start-container">
                <button type="submit" class="button-blue" :form="userForm.id">
                    <span class="button-label">
                        {{$t("configurationButton")}}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { DynamicForm } from '@asigloo/vue-dynamic-forms';
import {
  FormField,
  FormValidation,
  required,
  email,
  pattern,
} from "@asigloo/vue-dynamic-forms";

const components = { DynamicForm };

const data = () => ({
  userForm: {
    id: "user-form",
    fields: [],
  },
});

const methods = {
  formSubmitted(values) {
   localStorage.setItem('user-form', JSON.stringify(values));
   this.$router.go(0);
  }
};

export default {
  name: 'CompileData',
  data,
  methods,
  components,
  mounted: function mounted(){
    var json = JSON.parse(localStorage.getItem('variabili'));
    if(json.length>0){
      for(var i=0; i< json.length; i++){
        this.userForm.fields.push(
              new FormField(
              { 
                  type: "text",
                  label: json[i].name,
                  name: "var-"+i,
                  validations: [
                      new FormValidation(required, this.$i18n.t('requiredField'))
                  ]
              })
          );
      }
    }else{
      var models = JSON.stringify([{vuoto:'vuoto'}]);
      localStorage.setItem('user-form', models);
      this.$router.go(0);
    }
  }
}
</script>