import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_URL
} from "../actions/auth";
import qs from 'qs';
import { USER_REQUEST } from "../actions/user";
import axios from "axios";

const state = {
  token: localStorage.getItem("user-token") || "",
  status: "",
  loading: false,
  errorLogin: "",
  loginjson: JSON.parse(localStorage.getItem("user-json")) || "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  loginJson: state => state.loginjson,
  loading: state => {
    return state.loading;
  }
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      axios({url: AUTH_URL+'verify.php', data: qs.stringify(user), method: 'POST' , 
        headers: {
          //'Accept' : '*/*',
          //'Access-Control-Allow-Origin': '*',
          //'Content-Type': 'application/x-www-form-urlencoded'
        }})
        .then(resp => {
            if(resp.data.success=='true'){
              const token = resp.data.token
              localStorage.setItem('user-token', token)
              localStorage.setItem('user-json', JSON.stringify(resp.data))
              axios.defaults.headers.common['Authorization'] = token
              commit(AUTH_SUCCESS, resp)
              dispatch(USER_REQUEST)
              resolve(resp)
            }else{
              commit(AUTH_ERROR, '')
            }
        })
        .catch(err => {
            commit(AUTH_ERROR, err)
            localStorage.removeItem('user-token')
            reject(err)
        })
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token"); 
      localStorage.removeItem("user-json"); 
      delete axios.defaults.headers.common['Authorization']
      resolve();
    });
  },
  ['loadingT']: ({ commit }) => {
    return new Promise(resolve => {
      commit('loadingT');
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.loginjson = resp.data;
    state.token = resp.data.token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "Login o password errata";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
    state.loginjson = "";
  },
  ['loadingT']: state => {
    state.loading = true;
    console.log('entrato nel loading')
  },
  ['loadingF']: state => {
    state.loading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
