<template>
  <div>
    <Login v-if="!isAuthenticated"/>
    <WaitingMessage v-if="isAuthenticated && !isModelli"/>
    <CompileData v-if="isAuthenticated && isModelli && !moduliCompiled"/>
    <Modules v-if="isAuthenticated && moduliCompiled" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT } from "actions/auth";
import WaitingMessage from '@/components/WaitingMessage.vue'
import CompileData from '@/components/CompileData.vue'
import Modules from '@/components/Modules.vue'
import Login from '@/components/Login.vue'

export default {
  name: 'Home',
  components: {
    WaitingMessage,
    CompileData,
    Login,
    Modules
  },
  data() {
    return {
      moduliCompiled: false
    };
  },
  mounted: function mounted(){
    if(localStorage.getItem('user-form') != null && localStorage.getItem('user-form') != undefined && localStorage.getItem('user-form') != ''){
      this.moduliCompiled = true;
    }
  },
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isModelli","loginJson"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => ``
    }),
  }
}
</script>
