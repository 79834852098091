import Vue from 'vue'
import vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import i18n from './i18n'
import VueSignaturePad from 'vue-signature-pad';
import VeeValidate from "vee-validate";

require ('./assets/main.scss');
Vue.config.productionTip = false
Vue.use(VueSignaturePad);
Vue.use(VeeValidate);
new Vue({
  router,
  vuex,
  store,
  axios,
  i18n,
  render: h => h(App)
}).$mount('#app')
