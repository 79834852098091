<template>
  <div>
    <span class="text-intro">{{ $t('loadingLabel') }}</span>
  </div>
</template>

<script>
export default {
  name: 'WaitingMessage'
}
</script>
