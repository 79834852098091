<template>
  <div id="app">
    <div class="loading" v-if="loading"><img src="./assets/images/loading.gif" /></div>
    <div class="header">
      <div class="rect" v-bind:class="{ 'minus': modelli }">
        <span class="header-title" v-if="!isAuthenticated">
          {{$t('configurationTitle')}}
        </span>
        <span class="header-title" v-if="isAuthenticated">
          {{user}}
        </span>
        <div class="user-container" v-if="modelli">
          <div class="circle">
            <div class="circle-user">
              <i class="fa fa-user"></i>
            </div>
          </div>
          <div class="user-data">
            <b>{{$t('welcome')}}</b>
          </div>
        </div>
      </div>
      <img src="./assets/images/screen.png" v-if="!modelli"/>
    </div>
    <div class="container">
      <router-view/>
    </div>
  </div>
</template>

<script>
//import i18n from './i18n'
import { mapGetters, mapState } from "vuex";
import axios from "axios";
import { AUTH_LOGOUT } from "actions/auth";
import { MODELLI_REQUEST, ACTIVATE_REQUEST } from "@/store/actions/modelli";
export default { 
  data: () => ({
    user : '',
    intervalid1: '',
    intervalid2: '',
    //moduliCompiled: false,
    datiUtente : ''
  }),
  computed: {
    ...mapGetters(["getProfile", "isAuthenticated", "isProfileLoaded","loginJson","loading","modelli","language"]),
    ...mapState({
      authLoading: state => state.auth.status === "loading",
      name: state => ``,
      language: state => ''
    }),
  },
  updated: function updated(){
    this.$i18n.locale = this.$store.getters.language;
    this.user = this.loginJson.customer;
    clearInterval(this.intervalid1)
    clearInterval(this.intervalid2)
    if(this.$store.getters.isAuthenticated){
      this.checkactive();
      this.intervalid1 = setInterval( this.checkactive , 5000);
      this.checkmoduli();
      this.intervalid1 = setInterval( this.checkmoduli , 5000);
    }
  },
  mounted: function mounted(){
    this.$i18n.locale = this.$store.getters.language;
    clearInterval(this.intervalid1)
    clearInterval(this.intervalid2)
    if(this.$store.getters.isAuthenticated){
      this.user = this.loginJson.customer;
      this.checkactive();
      this.intervalid1 = setInterval( this.checkactive , 5000);
      this.checkmoduli();
      this.intervalid1 = setInterval( this.checkmoduli , 5000);
    }
    if(localStorage.getItem('user-form') != null && localStorage.getItem('user-form') != undefined && localStorage.getItem('user-form') != ''){
      this.datiUtente = JSON.parse(localStorage.getItem('user-form'));
    }
  }, 
  beforeDestroy () {
    clearInterval(this.intervalid1)
    clearInterval(this.intervalid2)
  },
  methods : {
    checkmoduli : function(){         
      this.$store.dispatch(MODELLI_REQUEST);  
    },
    checkactive : function(){         
      this.$store.dispatch(ACTIVATE_REQUEST); 
    }
  }
}
</script>