<template>
    <div>
        <h1>{{currentTitle}}</h1>
        <form @submit.prevent="validateBeforeSubmit" class="form" id="frm">
            <div v-for="(element, index) in elementsForm" :key="index">
                <div class="testo center" v-if="element.type=='1'" v-html="element.text"></div>
                <div class="center" v-if="element.type=='2'">
                    <div class="testo"><b>{{element.text}}</b></div>
                    <div class="form-row" v-for="(blocco, index2) in elementsForm[index].blocchi" :key="index2">
                        <label class="label" v-if="element.required=='1'">
                            {{blocco.label}}<br>
                            <input type="radio" v-model=element.value :name="`componente_${element.id}`" :value=blocco.id  v-validate="'required'"> 
                        </label>
                        
                        <label class="label" v-if="element.required=='0'">
                            {{blocco.label}}<br>
                            <input type="radio" v-model=element.value :name="`componente_${element.id}`" :value=blocco.id>  
                        </label>
                    </div>
                    <span class="error" v-if="errors.has(`componente_${element.id}`) && element.required=='1'">{{$t('requiredField')}}</span>
                </div>
                <div class="center" v-if="element.type=='3'">
                    <div class="testo"><b>{{element.text}}</b></div>
                    <div class="form-row" v-for="(blocco, index2) in elementsForm[index].blocchi" :key="index2">
                        <label class="label" v-if="element.required=='1'">
                            {{blocco.label}} <br>
                            <input type="checkbox" v-model=element.value :name="`componente_${element.id}`" :value=blocco.id  v-validate="'required'"> 
                        </label> 
                        <label class="label" v-if="element.required=='0'">
                            {{blocco.label}} <br>
                            <input type="checkbox" v-model=element.value :name="`componente_${element.id}`" :value=blocco.id>
                        </label> 
                    </div>
                    <span class="error" v-if="errors.has(`componente_${element.id}`) && element.required=='1'">{{$t('requiredField')}}</span>
                </div>
                <div class="testo center" v-if="element.type=='4'">
                    <b>{{element.text}}</b>
                    <div v-if="element.required=='1'">
                        <textarea v-model=element.value :name="`componente_${element.id}`" v-validate="'required'"></textarea>
                    </div> 
                    <div v-if="element.required=='0'"> 
                        <textarea v-model=element.value :name="`componente_${element.id}`"></textarea>
                    </div>
                    <span class="error" v-if="errors.has(`componente_${element.id}`) && element.required=='1'">{{$t('requiredField')}}</span>
                </div>
            </div>
            <div v-if="showErrorSign" class="alert alert-danger">{{$t('signAlert')}}</div>
            <div class="wrapper" id="wrapper">
                <div id="signature-pad" class="signature-pad">
                <div class="signature-pad--body">
                    <VueSignaturePad ref="signaturePad" />
                </div>
                <div class="signature-pad--footer">
                    <div class="description">{{$t('signLabelTop')}}</div>
                    <div class="signature-pad--actions">
                    <div>
                        <button class="button-sign clar" @click="undo">{{$t('cancelSign')}}</button>
                    </div>
                    <div>
                        <button class="button-sign save" type="submit">{{$t('confirmSign')}}</button>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { Validator } from 'vee-validate';
import {openDB} from 'idb';
const db = openDB('SignatiApp', 2, {
  upgrade(db) {
    db.createObjectStore('modules');
  },
});

export default {
    name:'DinamicModel',
    props: {
        currentNode: '',
        currentTitle: '',
        currentIndex : '',
        save:'',
    },
    components:{
        Validator
    },
    data(){
        return{
            showSign: false,
            moduleForm: {
                id: "module-form",
                fields: [],
            },
            elementsForm:[],
            dataFields: [],
            required: false,
            showErrorSign: false,
        }
    },
    mounted: function(){
        var json = this.currentNode[0];
        for(var i=0; i< json.length; i++){
            var text = '';
            if(json[i].tipo!='1'){
                text  = json[i].title;
            }else{
                text  = this.replaceString(json[i].text)
            }
            this.elementsForm.push({
                id: json[i].id,
                type: json[i].tipo,
                required: json[i].required,
                text: text,
                value: [],
                blocchi:[]
            })
            
            if(json[i].tipo=='2' || json[i].tipo=='3'){
                for(var j=0;j<json[i].blocchi.length;j++){
                    this.elementsForm[i].blocchi.push({
                        id: json[i].blocchi[j].id,
                        label: json[i].blocchi[j].title
                    });
                }
            }
        }
    },
    methods:{
        undo() {
            this.$refs.signaturePad.clearSignature();
        },
        replaceString: function(string){
            let stringa = string.toString();
            var vars = JSON.parse(localStorage.getItem('variabili'));
            var form = JSON.parse(localStorage.getItem('user-form'));
            for(var i=0;i<vars.length; i++){
                var val = vars[i].value.toString();
                val = val.replace(/\?\?_/,'').replace(/_\?\?/,'');
                var regex = new RegExp(`\\?\\?_${val}_\\?\\?`, 'gi');
                stringa = stringa.replace(regex, form['var-'+i]);
            }
            return stringa;
        },
        validateBeforeSubmit(e){      
            this.$validator.validateAll().then((result) => {
                if (result) {
                    var firma = this.$refs.signaturePad.saveSignature().data;
                    if(firma!='' && firma!=undefined){
                        this.showErrorSign = false;
                        this.getItem(this.currentIndex).then(resp => {
                            resp['response'] = this.elementsForm;
                            resp['sign'] = firma;
                            resp['stato'] = true;
                            this.delDb(this.currentIndex)
                            this.saveDb(this.currentIndex,resp)
                            this.save('');
                            this.$refs.signaturePad.clearSignature();
                        }).catch(err => { console.log(err) });
                        return;
                    }else{
                        this.showErrorSign = true;
                    }
                }
            });
        },
        async saveDb(key, val) {
            return (await db).put('modules', val, key);
        },
        async delDb(key) {
            return (await db).delete('modules', key);
        },
        async clearDb() {
            return (await db).clear('modules');
        },
        async getKeys() {
            return (await db).getAllKeys('modules');
        },
        async getItem(key) {
            return (await db).get('modules', key);
        },
    }
}
</script>