import { MODELLI_REQUEST, MODELLI_ERROR, MODELLI_SUCCESS, ACTIVATE_REQUEST, MODELLI_URL } from "../actions/modelli";
import axios from "axios";
import Vue from "vue";
import qs from 'qs';
import { AUTH_LOGOUT } from "../actions/auth";

const state = { 
  modelli: false,
  //modelli: localStorage.getItem("modelli") || "",
  variabili: JSON.parse(localStorage.getItem("variabili")) || "",
  status: "", 
  language: 'it',
  profile: {} 
};

const getters = {
  isModelli: state => !!state.modelli,
  variabiliJson: state => state.variabili,
  language: state=> state.language,
  modelli: state => {
    return state.modelli;
  }
};

const actions = {
  [MODELLI_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: MODELLI_URL+'checkmoduli.php', 
        method: 'POST', 
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: 'token='+localStorage.getItem("user-token")
      })
      .then(resp => {
        if(resp.data.success=='true'){
          return new Promise(resolve => {
            commit(MODELLI_SUCCESS,resp)
            //localStorage.setItem('modelli', true);
            localStorage.setItem('variabili', JSON.stringify(resp.data.fields));
            resolve(resp)
          });
        }else{
          return new Promise(resolve => {
            commit(MODELLI_ERROR);
            //localStorage.removeItem("modelli"); 
            localStorage.removeItem("variabili"); 
            localStorage.removeItem("user-form"); 
            localStorage.removeItem("user-models"); 
            resolve(resp)
          });
        }
      })
      .catch(err => {

      });
    });
  },
  [ACTIVATE_REQUEST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: MODELLI_URL+'checklogged.php', 
        method: 'POST', 
        headers:{
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: 'token='+localStorage.getItem("user-token")
      })
      .then(resp => {
        if(resp.data.success=='true'){
          resolve(resp)
        }else{
          return new Promise(resolve => {
            commit(AUTH_LOGOUT);
            localStorage.removeItem("user-token"); 
            localStorage.removeItem("user-json"); 
            resolve(resp)
          });
        }
      })
      .catch(err => {

      });
    });
  }
};

const mutations = {
  [MODELLI_REQUEST]: state => {
    state.status = "loading";
  },
  [MODELLI_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.modelli = true;
    state.language = resp.data.lingua;
  },
  [MODELLI_ERROR]: state => {
    state.status = "error";
    state.modelli = false;
  },
  [AUTH_LOGOUT]: state => {
    state.profile = {};
    state.language = 'it';
  },
  [ACTIVATE_REQUEST]: state => {
    state.profile = {};
  }   
};

export default {
  state,
  getters,
  actions,
  mutations
};
